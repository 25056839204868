import { create } from 'zustand';

type Props = {
  open: boolean;
  position: 'left' | 'right';
  closeButtonPosition?: 'left' | 'right';
  width?: number;
  link?: string | null;
  customDataNode?: React.ReactNode | null;
  path?: string | null;
  setOpen: (open: boolean) => void;
  setPosition: (position: 'left' | 'right') => void;
  setCloseButtonPosition: (closeButtonPosition: 'left' | 'right') => void;
  setWidth: (width: number) => void;
  setLink: (link: string | null) => void;
  setCustomDataNode: (customDataNode: React.ReactNode | null) => void;
  setPath: (path: string | null) => void;
  reset: (options?: Partial<Omit<Props, 'reset' | 'open'>>) => void;
};

export const useSheetComponent = create<Props>((set) => ({
  open: false,
  position: 'right',
  closeButtonPosition: 'right',
  width: 400,
  link: null,
  customDataNode: null,
  pathname: null,
  setOpen: (open) => set({ open }),
  setPosition: (position) => set({ position }),
  setCloseButtonPosition: (closeButtonPosition) => set({ closeButtonPosition }),
  setWidth: (width) => set({ width }),
  setLink: (link) => set({ link }),
  setCustomDataNode: (customDataNode) => set({ customDataNode }),
  setPath: (path) => set({ path }),
  reset: (options) =>
    set((state) => ({
      open: false,
      ...options,
    })),
}));
