import { useQuery } from '@tanstack/react-query';

import {
  IdentifyDataForPosthog,
  getIdentifyDataForPosthog,
} from '@/app/actions/getIdentifyDataForPosthog';

type GetIdentifyDataForPosthog = {
  enabled: boolean;
};

export function useGetIdentifyDataForPosthog({
  enabled,
}: GetIdentifyDataForPosthog) {
  return useQuery<IdentifyDataForPosthog>({
    enabled,
    queryKey: ['identify-data-for-posthog'],
    queryFn: () => getIdentifyDataForPosthog(),
  });
}
