import { FC } from 'react';

import { SvgProps } from './types';

const CursorColResizeIcon: FC<SvgProps> = ({ width = '32', height = '32' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      viewBox="0 0 32 32"
    >
      <path
        d="M14 8V14.006H12.979V10.54L7 16.012L12.979 21.473V17.995H14V24H14.02H17.981V17.994H19.039V21.461L25 15.988L19.039 10.527V14.005H17.981V8H14Z"
        fill="white"
      />
      <path
        d="M23.3402 15.989L20.0202 12.766V15.02H17.0002V9.06001H16.9812V9.04001H15.0402V15.04H11.9802V12.76L8.66016 16.011L11.9802 19.235V16.981H15.0402V22.981H15.0602H16.9812H17.0002V16.981H20.0202V19.24L23.3402 15.989Z"
        fill="black"
      />
    </svg>
  );
};

export default CursorColResizeIcon;
