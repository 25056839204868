'use client';

import React, { useCallback, useEffect, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { usePathname } from 'next/navigation';

import { useSheetComponent } from '@/store/sheet';

import CursorColResizeIcon from './icons/cursor-col-resize-icon.component';

type Props = {
  className?: string;
  customDataClassName?: string;
};

const SheetComponent = ({ className, customDataClassName }: Props) => {
  const pathname = usePathname();

  const [
    open,
    position,
    closeButtonPosition,
    width,
    link,
    customDataNode,
    path,
    setWidth,
  ] = useSheetComponent((state) => [
    state.open,
    state.position,
    state.closeButtonPosition,
    state.width,
    state.link,
    state.customDataNode,
    state.path,
    state.setWidth,
  ]);

  const [isResizing, setIsResizing] = useState(false);

  const handleMouseDown = useCallback(() => {
    setIsResizing(true);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        const newWidth =
          position === 'left' ? e.clientX : window.innerWidth - e.clientX;
        setWidth(newWidth);
      }
    },
    [isResizing, position, setWidth],
  );

  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  const handleCloseButton = () => {
    useSheetComponent.getState().reset({
      width: 400,
      link: null,
      customDataNode: null,
    });
  };

  useEffect(() => {
    if (path && path !== pathname) {
      useSheetComponent.getState().reset({
        width: 400,
        link: null,
        customDataNode: null,
      });
    }
  }, [pathname]);

  return (
    <>
      {open && (
        <div
          className={cn(
            'group fixed top-0 z-50 h-dvh w-full min-w-60 max-w-[50%] bg-white shadow-lg',
            {
              'left-0 border-r': position === 'left',
              'right-0 border-l': position === 'right',
            },
            className,
          )}
          style={{ width }}
        >
          <div
            className={cn(
              'absolute top-1/2 -translate-y-1/2 rounded-full p-6 opacity-0 group-hover:opacity-100',
              {
                '-right-[40.5px]': position === 'left',
                '-left-[40.5px]': position === 'right',
              },
            )}
          >
            <div
              className="cursor-pointer rounded-full"
              onMouseDown={handleMouseDown}
            >
              <CursorColResizeIcon />
            </div>
          </div>

          <div className="flex w-full items-center border-b p-4">
            <button
              className={cn(
                'rounded-full text-gray-400 transition-colors hover:text-gray-600',
                {
                  'ml-0': closeButtonPosition === 'left',
                  'ml-auto': closeButtonPosition === 'right',
                },
              )}
              onClick={handleCloseButton}
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          {customDataNode ? (
            <div
              className={cn(
                'h-[calc(100%-57px)] max-h-[calc(100%-57px)] min-h-[calc(100%-57px)] w-full before:overflow-y-auto',
                customDataClassName,
              )}
            >
              {customDataNode}
            </div>
          ) : link ? (
            <div className="h-[calc(100%-57px)] w-full overflow-y-auto">
              <iframe
                src={link}
                title="External Content"
                className="h-full w-full border-0"
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default SheetComponent;
