import(/* webpackMode: "eager", webpackExports: ["GTMBodyScript"] */ "/vercel/path0/apps/web/src/app/gtm-body-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GTMHeadScript"] */ "/vercel/path0/apps/web/src/app/gtm-head-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/vercel/path0/apps/web/src/app/next-auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogProvider"] */ "/vercel/path0/apps/web/src/app/post-hog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/session-checker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/wrapper/query-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/wrapper/tooltip-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/sheet.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/toast-wrapper.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/vercel/path0/apps/web/src/context/modalContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.4/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/styles/global.scss");
